import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Segment",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/segment.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Segment`}</h1>
    <p>{`Sync your Crisp with Segment.`}</p>
    <hr></hr>
    <p>{`Segment is a platform allowing to connect customer data from 200 platforms each other. Your team is now able to use their favorite tools to personalize campaigns, analyze product usage, and more. Thanks to Crisp and Segment, you'll be able to provide a world-class customer relationship.`}</p>
    <p>{`Benefits of using Crisp with Segment:`}</p>
    <ul>
      <li parentName="ul">{`Centralize customer data with no coding Skills`}</li>
      <li parentName="ul">{`Build custom Analytics with third-party solutions like Amplitude or Mixpanel`}</li>
      <li parentName="ul">{`Build a single view of the customer`}</li>
      <li parentName="ul">{`Discover what's most important to each users and personalize every interaction through triggers or drip email campaigns`}</li>
      <li parentName="ul">{`Discover what's most important to each users`}</li>
      <li parentName="ul">{`Automatically update audiences with a few clicks (No more CSV stuff!)`}</li>
      <li parentName="ul">{`Empower sales to engage the right person`}</li>
      <li parentName="ul">{`Remove recent buyers from your audiences to improve their experience`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      